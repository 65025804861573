"use strict";

// go top
$(document).ready(function(){
  $(window).scroll(function(){
    if ($(this).scrollTop() > 100) {
    $('.scrollToTop').fadeIn();
    } else {
    $('.scrollToTop').fadeOut();
    }
  });

  $('.scrollToTop').click(function(){
    $('html, body').animate({scrollTop : 0},400);
    return false;
  });
});

// menu
function openNav() {
        document.getElementById("mySidenav").style.width = "100vw";
        document.getElementById("main").style.marginLeft = "0";
}

function closeNav() {
        document.getElementById("mySidenav").style.width = "0";
        document.getElementById("main").style.marginLeft = "0";
}


